import React, { useState } from "react";
import "./SolorPanelCard.css";
import polycrystalline from "../../img/Solar-Panel/Polycrystalline.jpg";
import TataPowerSolor from "../../img/sellers/tata-solar.jpg";
import AdaniSolar from "../../img/sellers/adani-solar.jpg";
import AnanyaSolar from "../../img/Attraction/ananya-solar.png";
import AdityaSolar from "../../img/Attraction/aditya-energy.png";
import ECEIndia from "../../img/Attraction/ece-india.png";

import PromoCode from "../../img/discount.png";

import CreateableReactSelect from "react-select/creatable";

export default function Polycrystalline() {
  const options = [
    { value: "1", label: "MC4 Connector" },
    { value: "2", label: "Solar DC Wires" },
    { value: "3", label: "Solar Structure" },
    { value: "4", label: "Crimping Tool" },
    { value: "5", label: "Junction Boxes" },
  ];

  const [userData, setUserData] = useState({});
  const [quotesTitle, setQuotesTitle] = useState("Get Quotes");
  const [auctionTitle, setAuctionTitle] = useState("Create a new auction");
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStep1, setCurrentStep1] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    setQuotesTitle(() => "Best Quotes Received");
    setCurrentStep(currentStep + 1);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    setQuotesTitle(() => "Submission");
    setCurrentStep(currentStep + 1);
  };

  const handleConfirm1 = (event) => {
    event.preventDefault();
    setAuctionTitle(() => "Submission");
    setCurrentStep1(currentStep1 + 1);
  };

  const onHandleClose = () => {
    setAuctionTitle("Create a new auction");
    setQuotesTitle("Get Quotes");
    setCurrentStep(0);
    setCurrentStep1(0);
  };



  return (
    <>
      <div className="container-fluid">
        <div className="row row-cols-1 row-cols-md-12 g-4">
          <div className="col">
            <div className="card1 row">
              <div className="col-md-6">
                <div className="card1-body">
                  <ul>
                    <li className="mb-3">
                      Polycrystalline panels absorbs 15% of sunlight available
                    </li>
                    <li className="mb-3">
                      These panels do not perform well in low light
                    </li>
                    <li className="mb-3">
                      Polycrystalline solar panels have lower efficiency than
                      monocrystalline solar panels as they are composed of
                      multiple silicon crystals due to which there is limited
                      room available for the electrons to move.
                    </li>
                    <li className="mb-3">
                      These panels are more affordable than monocrystalline
                      solar panels as the manufacturing process is simpler and
                      less silicon is wasted during the whole process.
                    </li>
                  </ul>

                  <div className="d-flex ">
                    <button
                      type="button"
                      style={{backgroundColor: "#1B6B44", color: "white"}}
                      class="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal4"
                    >
                      Get Quotes
                    </button>
                    <button
                      type="button"
                      style={{backgroundColor: "#96A53F" ,color: "white"}}
                      class="btn ms-3"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal5"
                    >
                      Create Auction
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <img src={polycrystalline} className="img-fluid mono-img" alt="polycrystalline"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal4"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-3">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {quotesTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onHandleClose}
              ></button>
            </div>

            <div className="modal-body">
              <div>
                {currentStep === 0 && (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">
                        Capacity (kW) <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(event) =>
                          setUserData({
                            ...userData,
                            capacity: event.target.value,
                          })
                        }
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                       Use Case{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="useCase"
                          value="Residential"
                          onChange={(event) =>
                            setUserData({
                              ...userData,
                              inverterWarranty: event.target.value,
                            })
                          }
                          required
                        />
                        <label className="form-check-label">Residential</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="useCase"
                          value="Commercial"
                          onChange={(event) =>
                            setUserData({
                              ...userData,
                              inverterWarranty: event.target.value,
                            })
                          }
                          required
                        />
                        <label className="form-check-label"> Commercial</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="useCase"
                          value="Industrial"
                          onChange={(event) =>
                            setUserData({
                              ...userData,
                              inverterWarranty: event.target.value,
                            })
                          }
                          required
                        />
                        <label className="form-check-label"> Industrial</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Insurance <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="insurance"
                          value="yes"
                          onChange={(event) =>
                            setUserData({
                              ...userData,
                              insurance: event.target.value,
                            })
                          }
                          required
                        />
                        <label className="form-check-label"> Yes </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="insurance"
                          value="no"
                          onChange={(event) =>
                            setUserData({
                              ...userData,
                              insurance: event.target.value,
                            })
                          }
                          required
                        />
                        <label className="form-check-label"> No</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Accessories
                      </label>
                      <CreateableReactSelect
                        isMulti
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">
                        Terrace Area (sq. ft)
                      </label>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        onChange={(event) =>
                          setUserData({
                            ...userData,
                            terraceArea: event.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Terrace photo</label>
                      <input
                        type="file"
                        onChange={(event) =>
                          setUserData({
                            ...userData,
                            terracePhoto: event.target.value,
                          })
                        }
                      />
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </form>
                )}

                {currentStep === 1 && (
                  <div>
                     <div className="flex-row">
                      <img
                        src={PromoCode}
                        className="discount"
                        alt="Promo Code"
                        height={32}
                        width={32}
                      />
                      <span className="ms-3">
                        {" "}
                        you can avail about 40% subsidy on this order.{" "}
                        <a
                          className="ms-5"
                          href="https://solarrooftop.gov.in/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click Here
                        </a>{" "}
                        to know more
                      </span>
                    </div>
                    <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                      <div className="col-4">
                        <img
                          src={AnanyaSolar}
                          className="imng-fluid mb-3"
                          height={42}
                          width={108}
                          alt="AnanyaSolar"
                        />
                      </div>
                      <div className="col-4">
                        <p> 79,100/-</p>
                      </div>
                      <div className="col-4">
                        <button
                          className=" btn btn-primary"
                          type="button"
                          onClick={handleConfirm}
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                      <div className="col">
                        <img
                          src={ECEIndia}
                          className="imng-fluid mb-3"
                          height={42}
                          width={108}
                          alt="ECEIndia"
                        />
                      </div>
                      <div className="col">
                        <p> 81,130/-</p>
                      </div>

                      <div className="col">
                        <button
                          className=" btn btn-primary"
                          type="button"
                          onClick={handleConfirm}
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                      <div className="col-4">
                        <img
                          src={AdityaSolar}
                          className="imng-fluid mb-3"
                          height={42}
                          width={108}
                          alt="AdityaSolar"
                        />
                      </div>
                      <div className="col-4">
                        <p> 84,095/-</p>
                      </div>
                      <div className="col-4">
                        <button
                          className="btn btn-primary "
                          type="button"
                          onClick={handleConfirm}
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                      <div className="col-4">
                        <img
                          src={TataPowerSolor}
                          className="imng-fluid mb-4"
                          height={38}
                          width={108}
                          alt="TataPowerSolor"
                        />
                      </div>
                      <div className="col-4">
                        <p> 89,020/-</p>
                      </div>

                      <div className="col-4">
                        <button
                          className=" btn btn-primary"
                          type="button"
                          onClick={handleConfirm}
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-3 g-4 mt-2">
                      <div className="col-4">
                        <img
                          src={AdaniSolar}
                          className="imng-fluid mb-3"
                          height={45}
                          width={108}
                          alt="AdaniSolar"
                        />
                      </div>
                      <div className="col-4">
                        <p>92,110/-</p>
                      </div>
                      <div className="col-4">
                        <button
                          className=" btn btn-primary "
                          type="button"
                          onClick={handleConfirm}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {currentStep === 2 && (
                  <div>
                    <span
                      style={{
                        fontSize: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      role="img"
                      aria-label="image"
                    >
                      🎉
                    </span>
                    <h1 className="text-center">Congratulations!</h1>
                    <p className="text-center">
                      Your order placed successfully.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Second Model  Create Auction */}

      <div
        className="modal fade"
        id="exampleModal5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-3">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {auctionTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onHandleClose}
              ></button>
            </div>

            <div className="modal-body">
              <div className="mb-3">
                <div>
                  {currentStep1 === 0 && (
                    <form onSubmit={handleConfirm1}>
                      <div className="mb-3">
                        <label className="form-label">
                          Capacity (kW) <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(event) =>
                            setUserData({
                              ...userData,
                              capacity: event.target.value,
                            })
                          }
                          required
                        />
                      </div>

                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                         Use Case{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="useCase"
                            value="Residential"
                            onChange={(event) =>
                              setUserData({
                                ...userData,
                                inverterWarranty: event.target.value,
                              })
                            }
                            required
                          />
                          <label className="form-check-label">Residential</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="useCase"
                            value="Commercial"
                            onChange={(event) =>
                              setUserData({
                                ...userData,
                                inverterWarranty: event.target.value,
                              })
                            }
                            required
                          />
                          <label className="form-check-label"> Commercial</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="useCase"
                            value="Industrial"
                            onChange={(event) =>
                              setUserData({
                                ...userData,
                                inverterWarranty: event.target.value,
                              })
                            }
                            required
                          />
                          <label className="form-check-label"> Industrial</label>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Insurance <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="insurance"
                            value="yes"
                            onChange={(event) =>
                              setUserData({
                                ...userData,
                                insurance: event.target.value,
                              })
                            }
                            required
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="insurance"
                            value="no"
                            onChange={(event) =>
                              setUserData({
                                ...userData,
                                insurance: event.target.value,
                              })
                            }
                            required
                          />
                          <label className="form-check-label"> No</label>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Accessories
                        </label>
                        <CreateableReactSelect
                          isMulti
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={options}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Maximum Amount to be paid{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          required
                          className="form-control"
                          onChange={(event) =>
                            setUserData({
                              ...userData,
                              terraceArea: event.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Terrace Area (sq. ft){" "}
                        </label>
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          onChange={(event) =>
                            setUserData({
                              ...userData,
                              terraceArea: event.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Terrace photo
                          <input
                            type="file"
                            onChange={(event) =>
                              setUserData({
                                ...userData,
                                terracePhoto: event.target.value,
                              })
                            }
                          />
                        </label>
                      </div>

                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </form>
                  )}

                  {currentStep1 === 1 && (
                    <div>
                      <span
                        style={{
                          fontSize: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        role="img"
                        aria-label="image"
                      >
                        🎉
                      </span>
                      <h1 className="text-center">Congratulations!</h1>
                      <p className="text-center">
                        Your auction created successfully. <br />
                        To see best bids received go to{" "}
                        <a
                          href="/profile"
                          style={{ textDecorationLine: "none" }}
                        >
                          My Profile
                        </a>{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
