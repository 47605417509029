import "./Attraction.css";

import Aditya from "../../img/Attraction/aditya-energy.png";
import Ananya from "../../img/Attraction/ananya-solar.png";
import Anshuman from "../../img/Attraction/anshuman.png";
import BioFuels from "../../img/Attraction/bio-fuels.png";
import Chemfield from "../../img/Attraction/chemfield.png";
import ECEIndia from "../../img/Attraction/ece-india.png";
import GreenEarth from "../../img/Attraction/green-earth.png";
import Vasumitra from "../../img/Attraction/vasumitra.png";

export default function Attraction() {
  return (
    <div className="container mt-5">
      <h1 className="text-center mb-5" style={{ fontWeight: "bold" }}>
        Prominent Names in the Current Traction
      </h1>
      <hr />
      <div className="row py-5 mt-5 ms-auto">
        <div className="col-md-3">
          <img className="attraction" src={Aditya} alt="Aditya" />
        </div>
        <div className="col-md-3">
          <img className="attraction" src={Ananya} alt="Ananya" />
        </div>
        <div className="col-md-3">
          <img className="attraction" src={Anshuman} alt="Anshuman" />
        </div>
        <div className="col-md-3">
          <img className="attraction" src={GreenEarth} alt="GreenEarth" />
        </div>
      </div>
      <div className="row py-3">
        <div className="col-md-3 mt-5">
          <img className="attraction" src={BioFuels} alt="BioFuels" />
        </div>
        <div className="col-md-3 mt-5">
          <img className="attraction" src={Chemfield} alt="Chemfield" />
        </div>
        <div className="col-md-3 mt-5">
          <img className="attraction" src={ECEIndia} alt="ECEIndia" />
        </div>
        <div className="col-md-3 mt-5" >
          <img className="attraction" src={Vasumitra} alt="Vasumitra" />
        </div>
      </div>
    </div>
  );
}
